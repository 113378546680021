import {
	SPARKY_NAV3_LEFT_NAV_EXTPOINT,
	SPARKY_NAV3_ROUTES_EXTPOINT,
	SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
	SPARKY_NAV3_TSG_EXPOSES_COMPAT_EXTPOINT,
} from '@sparky/framework/extpoints';
// import type { ExtensionsConfig, ExtensionsExports, MicroAppConfig, NavParams, StoreState } from '@sparky/framework';
/* Warning: please do not statically import any dependencies here except for static assets like icons or from sparky framework */
import { UI_VERSION, MSP_ENABLED } from 'src/version';
import Registry from '@reporting-n-viz/oneapp-extensibility';
import _ from 'lodash';
import { _T } from 'src/utils/i18n-utils';
import { APP_BASE_URL_PREFIX, MSP_APP_BASE_URL_PREFIX } from 'src/AppConfig';
import { getRoutesExtensions } from 'src/SparkyNavV3RoutesExtension.js';
import { store as sparkyStore } from 'sparky-framework';
import { checkIsFeatureEnabled } from 'src/utils/featureFlagUtils';
import {
	isNGFWDisabled,
	isScmBaseTenant,
	PANORAMA_CONFIG,
	FAWKES_CONFIG,
	isAdminRole,
	isUserRoleSWGAdmin,
	hasRoleExists,
	isViewPermissionEnabled,
	getRBAPermission,
} from 'src/appUtils';
import {
	PRISMA_ACCESS_RBA,
	IOT_LICENSE_APP_ID,
	SASE_PRISMA_ACCESS_BRAND,
	PROJECT_ADMIN,
	PROJECT_ADMIN_PUSH,
} from 'service-lib/services/constants';
import { isAppOnMaintenance } from 'src/containers/main/notifications/utils';
import {
	isNotificationCallComplete,
	saseAvail,
	isManageEnabled,
	isWorkflowsEnabled,
	isPanoramaManaged,
	isMaintenanceMode,
} from 'src/loadUtils';

const FEATURE_KEY = 'fawkes.features';
const LICENSE_KEY = 'fawkes.license';
const GPEP_KEY = 'fawkes.cloudServices'; // fetching data from /cloud-services api
const NOTIFICATIONS_KEY = 'fawkes.notifications';
const PRISMA_PAE = 'prisma_access_edition';
const PRISMA_PA = 'prisma_access';
const SCM_APP_ID = 'strata_cloud_manager';
const STRATA_INSIGHTS = 'strata_insights';
const FORM_FACTOR_TYPE_NGFW = 'ngfw';
const FORM_FACTOR_TYPE_FLEX = 'flex';
const FORM_FACTOR_TYPE_PA = 'prisma_access';
const FORM_FACTOR_TYPE_CNGFW = 'cngfw';
const LOGGING_SERVICE_EXTENSION_NAMES = ['Logging Service', 'logging_service', 'Strata Logging Service'];

const isSCMPremium = (tsgInstances) => {
	for (const tsgInstance of tsgInstances) {
		// 2 conditions can result in SCM premium
		// FS Link: https://confluence-dc.paloaltonetworks.com/pages/viewpage.action?pageId=294784739#SCMPremiumActivation(NGFW,PrismaAccess,VMFlex)-Howtoidentifyproducttiers?
		// Check strata_cloud_manager app id and if its entitlements have NGFW or FLEX form factor then its premium
		// Check prisma_access_edition app id and if its entitlements have prisma_access form factor then its premium
		if (tsgInstance.app_id === SCM_APP_ID || tsgInstance.app_id === PRISMA_PAE) {
			if (tsgInstance.entitlements && tsgInstance.entitlements.length > 0) {
				for (const entitlement of tsgInstance.entitlements) {
					if (
						entitlement.app_id === SCM_APP_ID &&
						(entitlement.form_factor_type === FORM_FACTOR_TYPE_NGFW ||
							entitlement.form_factor_type === FORM_FACTOR_TYPE_FLEX ||
							entitlement.form_factor_type === FORM_FACTOR_TYPE_PA ||
							entitlement.form_factor_type === FORM_FACTOR_TYPE_CNGFW)
					) {
						return true;
					}
				}
			}
		}
	}
	return false;
};

const isTenantWithCDL = (tsgInstances) => {
	for (const tsgInstance of tsgInstances) {
		if (LOGGING_SERVICE_EXTENSION_NAMES.includes(tsgInstance.app_id)) {
			return true;
		}
	}
	return false;
};

const CACHE_MAP = {
	[FEATURE_KEY]: (value) => {
		const valueObj = typeof value === 'string' ? JSON.parse(value) : value;
		if (valueObj && !valueObj?.toggles) {
			return [];
		}
		return valueObj?.toggles.map((item) => {
			return {
				name: item?.fn,
				toggle: item.t,
			};
		});
	},
	[LICENSE_KEY]: (value) => {
		if (typeof value === 'string') {
			return JSON.parse(value);
		}
		return value;
	},
	[GPEP_KEY]: (value) => {
		if (!value) return {};
		const cloudServices = typeof value === 'string' ? JSON.parse(value) : value;
		if (cloudServices?.[0] && !_.isEmpty(cloudServices[0])) {
			const enableValues = { result: { enable: {} } };
			Object.keys(cloudServices[0])?.forEach((key) => {
				const enableKey = key?.replaceAll('_', '-');
				enableValues['result']['enable'][enableKey] = cloudServices[0]?.[key] === true ? 'yes' : 'no'; // Response from 'sase/config/v1/cloud-services' api is different from  'configByPath?type=container&folder=Mobile%20Users%20Container&prefix=plugins/cloud_services&subPath=enable' .Need to update values to support existing Ui framework
			});

			return enableValues;
		}
		return {};
	},
	[NOTIFICATIONS_KEY]: (value) => {
		if (typeof value === 'string') {
			return JSON.parse(value);
		}
		return value;
	},
};

// below is for nav v3 using extensions
export const extensions = ({
	sparkyFramework: {
		events,
		onAuthed,
		StartupManager,
		getFrameworkServiceUrl,
		dedupPromise,
		fawkesUtils: { getRedirectURL, getAuthHeaders },
		getAuthState,
		getState,
		getMainState,
		hasTsgSupport,
		dispatch,
	},
	sparkyFramework,
}) => {
	const getTenantTypeFromSparky = () => {
		if (
			getAuthState()?.instances?.get('prisma_access_edition_onprem') ||
			getAuthState()?.instances?.get('prisma_access_panorama')
		) {
			return StartupManager.PANORAMA_CONFIG;
		}
		return StartupManager.FAWKES_CONFIG;
	};
	const isPaEnabled = () => {
		const instances = getAuthState()?.instances;
		if (instances.has('prisma_access_edition_onprem')) {
			return instances.has('prisma_access_panorama');
		}
		if (instances.has('bulk_config')) {
			return instances.has('prisma_access_edition');
		}
		return Boolean(instances?.has('prisma_access_edition')) && Boolean(instances?.has('prisma_access'));
	};

	const isNgfwEnabled = () => {
		const instances = getAuthState()?.instances;
		if (!instances || !instances.length) {
			return false;
		}
		if (instances.has('bulk_config')) {
			return true;
		}
		// ADI-32593 For SCM base tenant need check 'strata_cloud_manager' instance
		return (
			instances.has('prisma_access') &&
			(instances.has('strata_insights') || instances.has('strata_cloud_manager'))
		);
	};

	const dedupPromiseGenerator = ({ url, auth, getAuthHeaders, cacheKey = '', timeout = 10000, fetch }) => {
		const cacheValue = auth.properties?.get(cacheKey);
		if (!_.isEmpty(cacheValue) && CACHE_MAP[cacheKey]) {
			return Promise.resolve(CACHE_MAP[cacheKey](cacheValue));
		}

		return dedupPromise(
			`${url}?tsgId=${auth.tsg_id}`,
			() => {
				// const controller = new AbortController();
				// const timeoutId = setTimeout(() => controller.abort(), timeout);
				return fetch(url, {
					// signal: controller.signal,
					timeout,
					withCredentials: true,
					method: 'GET',
					responseType: 'json',
					headers: getAuthHeaders(),
				})
					.then(async (response) => {
						let resp = await response.json();
						// clearTimeout(timeoutId);
						return resp;
					})
					.catch((err) => {
						console.log(err);
						return err;
					});
			},
			{ keepPromiseIn: 10000 },
		);
	};

	const contributeExtension = () => {
		console.log('contributeExtension customcheck trigger');
		const extensionKey = 'aiops.posture_settings';
		const path = '/security-checks-cm';
		const subKey = `${path}-active`;
		const extensionId = 'aiops-ngfw-security-checks-cm-active';

		const postureSettingsExtensions = Registry.getExtensions(extensionKey) || [];
		const existingExtension = postureSettingsExtensions.find((ext) => ext.key === subKey);
		if (existingExtension) {
			return;
		}
		try {
			Registry.contributeExtension(extensionKey, {
				id: extensionId,
				config: {
					key: subKey,
					path,
					name: {
						defaultMessage: 'Security Checks',
					},
					component: () => import('./containers/securityChecks/index'),
					position: ['start'],
				},
			});
		} catch (error) {
			console.error('Failed to contribute extension', error);
		}
	};

	const load = async ({ fetch }) => {
		const auth = getAuthState();
		//Entering the TSG
		let info = getAuthState()?.instances?.get('prisma_access');
		if (MSP_ENABLED && !info) {
			info = getAuthState()?.instances?.get('bulk_config');
		}
		let adminURL = info?.runtime_attributes?.admin_api_url;

		const type = getTenantTypeFromSparky();

		fetch(adminURL + `/api/activity/login`, {
			withCredentials: true,
			method: 'GET',
			responseType: 'json',
			headers: getAuthHeaders(),
		})
			.then(async (response) => {
				console.log('Entering the TSG ID: ', auth.tsg_id, 'TSG Name: ', auth.tsg_name);
			})
			.catch((err) => {
				console.log(err);
			});

		events?.once?.('logout', () => {
			const url = new URL(`/api/activity/logout`, adminURL).toString();
			return dedupPromise(
				`${url}?tsg_id=${auth.tsg_id}`,
				() =>
					fetch(url, {
						withCredentials: true,
						method: 'GET',
						responseType: 'json',
						headers: getAuthHeaders(),
					})
						.then(async (response) => {
							console.log('Leaving the TSG ID: ', auth.tsg_id, 'TSG Name: ', auth.tsg_name);
						})
						.catch((err) => {
							console.log(err);
						}),
				{ keepPromiseIn: 10000 },
			);
		});

		const getNotificationsInfo = dedupPromiseGenerator({
			url: new URL('/api/v1/notifications?&readStatus=false', adminURL).toString(),
			auth,
			getAuthHeaders,
			cacheKey: NOTIFICATIONS_KEY,
			fetch,
		});

		const getFawkesLicenseInfo = dedupPromiseGenerator({
			url: new URL('/api/extensions/gpcs/license', getRedirectURL()).toString(),
			auth,
			getAuthHeaders,
			cacheKey: LICENSE_KEY,
			fetch,
		});

		const getGpEPPromise = dedupPromiseGenerator({
			url: new URL(
				'/api/config/v9.2/configByPath?type=container&folder=Mobile%20Users%20Container&prefix=plugins/cloud_services&subPath=enable',
				getRedirectURL(),
			).toString(),
			auth,
			getAuthHeaders,
			cacheKey: GPEP_KEY,
			fetch,
		});

		const getFeaturesPromise = dedupPromiseGenerator({
			url: new URL('/api/features', adminURL).toString(),
			auth,
			getAuthHeaders,
			cacheKey: FEATURE_KEY,
			fetch,
		});

		/********** decouple sparky call*/
		const instances = getAuthState()?.instances;
		const extensions = [];

		instances.forEach((instance) => {
			const ext = {
				id: instance?.tenant_id,
				active: instance?.status === 'running',
				info: JSON.stringify(instance),
				internalTenantId: MSP_ENABLED
					? instances?.get('bulk_config')?.tenant_id || instances?.get('prisma_access')?.tenant_id
					: instances?.get('prisma_access')?.tenant_id,
				name: instance?.app_id,
				supportAccountId: instance?.support_account_id,
			};
			extensions.push(ext);
		});

		info.tsgInstances = instances;

		const authInfo = {
			credentials: getAuthState()?.user,
			entitlements: getAuthState()?.instances,
			extraInfo: {
				appPortal: getFrameworkServiceUrl('app_portal'),
			},
		};
		const loggedInUser = {
			email: getAuthState()?.user?.email,
			currentInstanceId: MSP_ENABLED
				? getAuthState()?.instances?.get('bulk_config')?.tenant_id ||
				  getAuthState()?.instances?.get('prisma_access')?.tenant_id
				: getAuthState()?.instances?.get('prisma_access')?.tenant_id,
			currentSupportAccountId: MSP_ENABLED
				? getAuthState()?.instances?.get('bulk_config')?.support_account_id ||
				  getAuthState()?.instances?.get('prisma_access')?.support_account_id
				: getAuthState()?.instances?.get('prisma_access')?.support_account_id,
			currentTsgId: getAuthState()?.tsg_id,
			profile: getAuthState()?.user,
			supportAccountIds: getAuthState()?.user?.supportAccountIds,
			supportaccountids: getAuthState()?.user?.supportaccountids,
			userName: getAuthState()?.user?.username,
		};
		const cluster = info?.runtime_attributes?.cluster;
		cluster.redirectURL = getRedirectURL();

		const scmFlags = () => {
			const instances = getAuthState()?.instances;
			if (!instances || !instances.length) {
				return {};
			}
			let isSCMPremiumTenant = false;
			if (instances.has(SCM_APP_ID)) {
				isSCMPremiumTenant = isSCMPremium(instances);
			} else {
				if (instances.has(STRATA_INSIGHTS) || instances.has(PRISMA_PA)) {
					isSCMPremiumTenant = true;
				}
			}
			return {
				is_scm_premium_tenant: isSCMPremiumTenant,
				is_scm_base_tenant: !isSCMPremiumTenant,
				is_tenant_with_cdl: isTenantWithCDL(instances),
			};
		};
		const tenant = {
			info,
			cluster: cluster,
			clusterName: info?.runtime_attributes?.cluster?.name,
			type,
			id: info?.tenant_id,
			tenantId: info?.tenant_id,
			supportAccountId: info?.support_account_id,
			sgId: info?.tsg_id,
			accountName: info?.support_account_name,
			iamControlled: info?.iam_controlled,
			state: info.status,
			extensions,
			pa_enabled: isPaEnabled(),
			ngfw_enabled: isNgfwEnabled(),
			scmFlags: scmFlags(),
			is_gov: info?.runtime_attributes?.is_fips_mode === 1,
		};
		loggedInUser.tenant = tenant;

		let fawkesMain = {};
		if (type !== StartupManager.PANORAMA_CONFIG) {
			await Promise.all([getFawkesLicenseInfo, getGpEPPromise, getNotificationsInfo, getFeaturesPromise])
				.then((response) => {
					fawkesMain = {
						licenseInfo: response?.[0],
						gpEpEnabled: _.isError(response?.[1])
							? { error: response?.[1]?.message || 'error' }
							: response?.[1]?.result?.enable,
						notificationsInfo: _.isError(response?.[2]) ? [] : response?.[2],
						featuresInfo: response?.[3],
						loggedInUser,
						tenant,
						authInfo,
						tokenInfo: getState()?.libTokens,
					};
					// dispatch({
					// 	type: 'set-values',
					// 	values: {
					// 		fawkesMain: fawkesMain,
					// 	},
					// });
				})
				.catch((e) => console.error(e));
		} else {
			const getMigrationStatusInfo = dedupPromiseGenerator({
				url: new URL('/api/migration/v1/customer/migration-status', getRedirectURL()).toString(),
				auth,
				getAuthHeaders,
			});
			let subTenantInfo = auth?.properties?.get('netsec.subtenant');
			await Promise.all([
				getMigrationStatusInfo,
				getNotificationsInfo,
				getFeaturesPromise,
				getFawkesLicenseInfo,
				getGpEPPromise,
			])
				.then((response) => {
					fawkesMain = {
						licenseInfo: response?.[3],
						migrationStatus: response?.[0],
						notificationsInfo: _.isError(response?.[1]) ? [] : response?.[1],
						featuresInfo: response?.[2],
						tenant,
						loggedInUser,
						tokenInfo: getState()?.libTokens,
						fqdnInfo: response?.[3],
						gpEpEnabled: _.isError(response?.[4])
							? { error: response?.[4]?.message || 'error' }
							: response?.[4]?.result?.enable,
						subTenantInfo,
					};
					// dispatch({
					// 	type: 'set-values',
					// 	values: {
					// 		fawkesMain: fawkesMain,
					// 	},
					// });
				})
				.catch((e) => console.error(e));
		}
		return fawkesMain;
	};

	const stateExt = {
		// declare ext for api calls (please avoid, and using MFE Propery instead)
		point: SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
		configs: [
			{
				id: !MSP_ENABLED ? 'panw.fawkes.tsg.state.scmload' : 'panw.msp-fawkes.tsg.state.scmload',
				config: {
					name: 'fawkesMain',
					requireFetch: true, // true for use the fetch function
					loader: async ({ fetch }) => {
						const localVersion = UI_VERSION;
						const loaderPromise = await load({ fetch });
						console.log(`${MSP_ENABLED ? '' : 'None'} MSP version Load - version: ${localVersion}`);
						return loaderPromise;
					}, // there will be a default timeout for 5s
				},
			},
		],
	};

	const leftNavExt = {
		// declare ext for left nav tree
		point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
		configs: [
			{
				id: !MSP_ENABLED ? 'panw.fawkes.nav.scm' : 'panw.msp-fawkes.nav.scm',
				config: {
					stateLoaders: ['auth', 'fawkesMain'],
					navigation: () => {
						const nav = !MSP_ENABLED
							? [
									{
										key: 'configuration',
										path: '/configuration',
										children: [
											{
												key: 'discovery',
												title: _T('Discovery'),
												path: '/workflows/discovery',
												// replacePaths: '/manage/example',
												avail: (...args) => {
													return isWorkflowsEnabled(...args) && !isNGFWDisabled();
												},
											},
											{
												key: 'maintenance-mode',
												title: _T('NGFW and {brandName}', {
													brandName: SASE_PRISMA_ACCESS_BRAND,
												}),
												path: '/maintenance-mode',
												avail: (configLocation) => {
													return isMaintenanceMode(configLocation);
												},
											},
											{
												key: 'panorama',
												title: _T('Panorama'),
												path: '/panorama',
												avail: (configLocation) => {
													return isPanoramaManaged(configLocation);
												},
											},
											{
												key: 'overview',
												title: _T('NGFW and {brandName}', {
													brandName: SASE_PRISMA_ACCESS_BRAND,
												}),
												path: '/overview',
												// replacePaths: '/manage/example',
												avail: (configLocation) => {
													return saseAvail(configLocation);
												},
											},
											{
												key: 'posture',
												title: _T('Posture'),
												path: `${APP_BASE_URL_PREFIX}/security-posture`,
												avail: (...args) => {
													// return (
													// 	isManageEnabled(...args) &&
													// 	isViewPermissionEnabled(getRBAPermission('security_posture')) &&
													// 	isNotificationCallComplete() &&
													// 	!isAppOnMaintenance() &&
													// 	!isScmBaseTenant()
													// );
													return true;
												},
												children: [
													{
														key: 'config-cleanup',
														title: _T('Config Cleanup'),
														path: `${APP_BASE_URL_PREFIX}/operation/optimize`,
														avail: (...args) => {
															return (
																isManageEnabled(...args) &&
																isViewPermissionEnabled(
																	getRBAPermission('security_posture'),
																) &&
																isNotificationCallComplete() &&
																!isAppOnMaintenance() &&
																!isScmBaseTenant()
															);
														},
													},
													{
														key: 'policy-optimizer',
														title: _T('Policy Optimizer'),
														path: `${APP_BASE_URL_PREFIX}/operation/policy-optimizer`,
														avail: (...args) => {
															return (
																isManageEnabled(...args) &&
																isViewPermissionEnabled(
																	getRBAPermission('security_posture'),
																) &&
																isNotificationCallComplete() &&
																!isAppOnMaintenance() &&
																!isScmBaseTenant()
															);
														},
													},
												],
											},
											{
												key: 'iotPolicyRecommendation',
												title: _T('IoT Policy Recommendation'),
												path: `${APP_BASE_URL_PREFIX}/iot-policy-recommendation`,
												avail: (args = {}) => {
													const { instances = [] } = args;
													const IoTLicense = instances.find(
														(i) => i['app_id'] === IOT_LICENSE_APP_ID,
													);
													return !!IoTLicense;
												},
											},
											{
												key: 'coloConnect',
												title: _T('Colo Connect'),
												path: '/workflows/colo-connect',
												avail: (
													configLocation,
													licenseInfo = sparkyStore.getState()?.fawkesMain?.licenseInfo,
													featuresInfo = sparkyStore.getState()?.fawkesMain?.featuresInfo,
												) => {
													return (
														checkIsFeatureEnabled(featuresInfo, 'colo-connect') &&
														isViewPermissionEnabled(
															getRBAPermission(PRISMA_ACCESS_RBA.coloConnect),
														)
													);
												},
											},
											{
												key: 'onboarding',
												title: _T('Onboarding'),
												path: '/workflows/onboarding',
												avail: (...args) => {
													return true;
												},
											},
											{
												key: 'operations',
												title: 'operations',
												path: `${APP_BASE_URL_PREFIX}/operation`,
												avail: function (...args) {
													const allChildrenUnAvail =
														this.children
															?.filter((item) => item.avail)
															.every(function (item) {
																return !item.avail?.call(item, ...args);
															}) || false;
													if (allChildrenUnAvail) return false;
													return (
														!isUserRoleSWGAdmin() &&
														isNotificationCallComplete() &&
														!isAppOnMaintenance()
													);
												},
												children: [
													{
														key: 'push-config',
														title: _T('Push Config'),
														path: `${APP_BASE_URL_PREFIX}/operation/push-config`,
														avail: (...args) => {
															return (
																isManageEnabled(...args) &&
																(isViewPermissionEnabled(
																	getRBAPermission('push_config'),
																) ||
																	hasRoleExists(PROJECT_ADMIN, true) ||
																	hasRoleExists(PROJECT_ADMIN_PUSH, true))
															);
														},
													},
													{
														key: 'push-status',
														title: _T('Push Status'),
														path: `${APP_BASE_URL_PREFIX}/operation/push-status`,
														avail: (...args) => {
															return (
																isManageEnabled(...args) &&
																(isViewPermissionEnabled(
																	getRBAPermission('push_config'),
																) ||
																	hasRoleExists(PROJECT_ADMIN, true) ||
																	hasRoleExists(PROJECT_ADMIN_PUSH, true))
															);
														},
													},
													{
														key: 'config-version-snapshot',
														title: _T('Config Version Snapshots'),
														path: `${APP_BASE_URL_PREFIX}/operation/snapshots`,
														avail: (...args) => {
															return (
																isManageEnabled(...args) &&
																isViewPermissionEnabled(getRBAPermission('push_config'))
															);
														},
													},
												],
											},
										],
									},
									{
										key: 'settings',
										path: '/settings',
										children: [
											{
												key: 'folder-management',
												title: _T('Folder Management'),
												path: '/workflows/firewall-setup/folder-management',
												avail: (...args) => {
													return (
														isWorkflowsEnabled(...args) &&
														isNotificationCallComplete() &&
														!isAppOnMaintenance() &&
														(StartupManager.getConfigManagedType() === FAWKES_CONFIG ||
															(StartupManager.getConfigManagedType() ===
																PANORAMA_CONFIG &&
																StartupManager.isMigrationStateEligibleForFawkesUI() &&
																!StartupManager.hideManagementUIBasedOnMigrationState())) &&
														!isNGFWDisabled() &&
														isViewPermissionEnabled(getRBAPermission('folder_management'))
													);
												},
											},
											{
												key: 'device-management',
												title: _T('Device Management'),
												path: '/workflows/firewall-setup/device-management',
												avail: (...args) => {
													return (
														isWorkflowsEnabled(...args) &&
														isNotificationCallComplete() &&
														!isAppOnMaintenance() &&
														(StartupManager.getConfigManagedType() === FAWKES_CONFIG ||
															(StartupManager.getConfigManagedType() ===
																PANORAMA_CONFIG &&
																StartupManager.isMigrationStateEligibleForFawkesUI() &&
																!StartupManager.hideManagementUIBasedOnMigrationState())) &&
														!isNGFWDisabled() &&
														isViewPermissionEnabled(getRBAPermission('device_management'))
													);
												},
											},
											{
												key: 'scope-management',
												title: _T('Scope Management'),
												path: '/manage/access-control/scope-management',
												avail: (...args) => {
													const feature_flag = false;
													return (
														isManageEnabled(...args) && isAdminRole(this) && !feature_flag
													);
												},
											},
										],
									},
							  ]
							: [
									{
										key: 'configuration',
										path: '/configuration',
										children: [
											{
												key: 'manage-snippets',
												title: 'Snippets',
												path: `${MSP_APP_BASE_URL_PREFIX}`,
												children: [
													{
														key: 'manage-snippets-overview',
														title: _T('Overview'),
														path: `${MSP_APP_BASE_URL_PREFIX}/overview`,
													},
												],
											},
										],
									},
							  ];
						return nav;
					},
					// isAllowed: () => !fxVars.msp,
				},
			},
		],
	};

	const routesExt = {
		// declare ext for routes
		point: SPARKY_NAV3_ROUTES_EXTPOINT,
		configs: [...getRoutesExtensions()],
	};

	const exposesExt = {
		point: SPARKY_NAV3_TSG_EXPOSES_COMPAT_EXTPOINT,
		configs: [
			{
				id: !MSP_ENABLED ? 'panw.fawkes.exposes.tsg' : 'panw.msp-fawkes.exposes.tsg',
				config: {
					exposes: () => {
						if (!MSP_ENABLED) {
							contributeExtension();
						}
						const exposes = {
							default: {
								// default means the micro-app name
								responsePage: () => import('./exposedFunctions.js'),
								ConfigAnalysis: () => import('src/containers/spiffy/config-analysis/ConfigAnalysis'),
								ConfigObjectRedirectMap: () => import('./exposeConfigObjectRedirectMap.js'),
								SecurityPolicy: () =>
									import('src/containers/onboarding/users/agentBasedAccess/securityPolicy'),
								ServiceConnectionConnectorSettings: () =>
									import(
										'src/containers/onboarding/privateApps/ztna-connector/ServiceConnectionConnectorSettings'
									),
							},
						};

						// const sbacFeatureFlag = getSbacFeatureFlag();
						// if (sbacFeatureFlag) {
						// 	exposes.['iam-scope-definition'] = {
						// 		cm_folder: () => import('src/containers/sbac/folderSelector/SbacFolderSelector'),
						// 		config_snippet: () => import('src/containers/sbac/snippetSelector/SbacSnippetSelector'),
						// 		remote_network: () => import('src/containers/sbac/remoteNetworkSelector/SbacRemoteNetworkSelector'),
						// 	};
						// }
						return exposes;
					},
				},
			},
		],
	};

	return [leftNavExt, stateExt, routesExt, exposesExt]; //routesExt]; // return all extensions
};
